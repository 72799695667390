html {
  font-size: 62.5%;
}

@font-face {
  font-family: 'Akira';
  src: url('./fonts/Akira.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas';
  src: url('./fonts/Bebas-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BankGothic';
  src: url('./fonts/BankGothicMediumBT.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent;
  -webkit-box-shadow: 0 0 0 1000px #071020 inset !important;
  -webkit-text-fill-color: white;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.8);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.8);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.8);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.8);
}
